import React from "react";
import Header from "../Header";
import Footer from "../Footer";

function TermsOfUse() {
  return (
    <div>
      <Header />
      <div className="pri-main">
        <h1>Terms of Use</h1>
        <p>
          This website is operated by edunomics.in, Inc (“edunomics”).
          Throughout this website (the “site”), the terms “we”, “us” and “our”
          refer to edunomics. edunomics offers this site, including all
          information, tools and services available from this site to you (the
          “Service”), conditioned upon your acceptance of all terms, conditions,
          policies and notices stated here (the “Terms”). If you do not agree to
          these Terms, you must not access the site nor do you have the right to
          use our Service. We reserve the right to update, change, or replace
          any part of these Terms by posting updates and/or changes here at
          which time they shall be binding on you. The headings used in this
          agreement are included for convenience only and will not limit or
          otherwise affect these Terms.
        </p>
      </div>
      <div className="pri-main2">
        <h1 style={{ fontSize: "24px", marginTop: "2rem" }}>
          1. GENERAL CONDITIONS
        </h1>
        <p>
          You may use the Site, including the content on it solely for the
          purposes stated on the Site itself and as described in this Agreement
          and in accordance with any Service Agreement that you have with us. We
          reserve the right to refuse Service to anyone for any reason at any
          time. Users are the sole creators of their accounts and edunomics may
          not independently verify the information provided. You are responsible
          for maintaining the confidentiality of your edunomics account and
          password. You shall be responsible for all uses of your account and
          password, whether or not authorized by you. You agree to immediately
          notify edunomics of any unauthorized use of your account or password.
        </p>
        <h2 style={{ fontSize: "24px", marginTop: "2rem" }}>
          2. ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION
        </h2>
        <p>
          Although edunomics attempts to provide accurate product descriptions
          and other information, we are not responsible if information made
          available on the site is not accurate, complete or current. The
          material on the site is provided for general information only and
          should not be relied upon or used as the sole basis for making
          decisions without consulting primary, more accurate, more complete or
          more timely sources of information. Any reliance on the material on
          the site is at your own risk.
        </p>
        <h2>3. THIRD-PARTY LINKS; AFFILIATES</h2>
        <p>
          When you visit the Site, we automatically collect certain information
          about your device, including information about your web browser, IP
          address, time zone, and some of the cookies that are installed on your
          device. Additionally, as you browse the Site, we collect information
          about the individual web pages or products that you view, what
          websites or search terms referred you to the Site, and information
          about how you interact with the Site. This information is collected
          using the following technologies:
        </p>
        <h2>
          4. USER COMMENTS, FEEDBACK AND OTHER PERSONAL INFORMATION AND
          SUBMISSIONS
        </h2>

        <p>
          We may also collect personal information from you that includes your
          name and email address, and such other information as we may require
          to provide our services. Also, if you contact us, we collect
          additional information such as your location, and any personal
          information that you provide in your contact request. In addition, we
          may receive information about you from other online or offline
          sources, including third parties from whom we validate consumer
          self-reported information, and verify information we already have
          about you. This helps us to update, expand and analyze our records and
          provide products and services that may be of interest to you.
        </p>
        <h2>5. PROHIBITED USES</h2>
        <p>
          We use your information to provide our services. Of course, you can
          opt-out at any time by providing us notice that you want us to delete
          and no longer use your information.
          <br />
          <br /> We also use information that we collect to help us screen for
          potential risk and fraud (in particular, your IP address), and more
          generally to improve and optimize our Site (for example, by generating
          analytics about how our customers browse and interact with the Site.
          <br />
          <br />
          Additionally, edunomics participates in affiliate advertising programs
          designed to provide a means for edunomics to generate revenue by
          advertising and linking to those affiliates’ products or services.
          When such affiliate relationships exist and there is an opportunity
          for edunomics to be compensated for referring you to the affiliate,
          edunomics will clearly and conspicuously disclose that to you.
        </p>
        <h2>6. DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY</h2>
        <p>
          We will share your information with scholarship providers, applicable
          academic institutions, and as otherwise necessary to provide the
          edunomics service to you. By using the edunomics service, you
          acknowledge, confirm, and consent to: (i) edunomics’s generation of
          revenue from its partnerships with scholarship providers, applicable
          academic institutions, and other third parties who are necessary for
          edunomics to provide its services to you, and (ii) edunomics’s use of
          your information and data on edunomics’s platform in order to do so.
          This includes third party processors (“Sub-processors”) which
          generally refers to technology vendors that need access to and use of
          your personal information to give us additional personal data about
          you, and to perform analytics and other work that we may need to
          outsource. These Sub-processors are bound by law and/or contract to
          protect the confidentiality and security of your personal information.
          They only process your personal information to provide requested
          services for us, and only act on our documented instructions. <br />
          <br /> For example, we use Google Analytics to help us understand how
          our customers use the Site. You can read more about how Google uses
          your information here. You can also opt-out of Google Analytics here.{" "}
          <br />
          <br />
          In addition to Google Analytics, we may share your information with
          other third parties in an aggregated and anonymous manner to assess
          the overall trends and information about our customers. This
          information will not contain any of your personal information and is
          only shared in an aggregated and anonymized manner. <br />
          <br /> Additionally, if you indicate you are interested in particular
          educational institutions in your profile or via other means when using
          edunomics’s platform, edunomics may share your data with those
          educational institutions from time to time. If you do not want
          edunomics to share your data with those educational institutions at
          all, please let us know by contacting privacy@edunomics.in.{" "}
          <br />
          <br />
          Finally, we may also share your information to comply with applicable
          laws and regulations, to respond to a subpoena, search warrant or
          other lawful request for information we receive, or to otherwise
          protect our rights.
        </p>
        <h2>10. ENTIRE AGREEMENT</h2>
        <p>
          The security of your information is very important. To help protect
          your information, we maintain reasonable physical, technical and
          administrative safeguards. We update and test our security technology
          on an ongoing basis. We restrict access to your personal information
          to those employees who need to know that information in order to
          provide services to you. In addition, we train our employees about the
          importance of confidentiality and maintaining the privacy and security
          of your information.
        </p>
        <h2>11. GOVERNING LAW</h2>
        <p>
          The Site and the Services we offer through it are U.S. based and are
          controlled, operated and administered by edunomics and its
          Sub-processors from offices and servers within the United States. The
          Site and this Privacy Policy is strictly intended for audiences
          located in the United States and are provided in accordance with and
          subject to applicable United States law. If you decide to continue to
          access our Site from your location outside the United States, you
          hereby agree that your use of the Site or any services we may offer
          are subject to this Policy and your personal information will be
          transferred or processed in the United States, and you may have fewer
          privacy rights and protections than you have in your country of
          origin.
        </p>
        <h2>12. PERSONAL INFORMATION</h2>
        <p>
          For more information about our privacy practices, if you have
          questions, or if you would like to make a complaint, please contact us
          by e-mail at privacy@edunomics.in or by mail using the details
          provided below: <br />
          <br />
          Effective Date: January 18, 2021.
          <br />
          <br /> edunomics.in, Inc., 133 Kearny #400, San Francisco CA
          94108 United States
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default TermsOfUse;
