import React from "react";
import "../styles/toast.css";
import { makeStyles } from "@material-ui/core/styles";
import { Alert, AlertTitle } from "@material-ui/lab";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "50%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));
export function DescriptionAlerts() {
  const classes = useStyles();

  return (
    <Alert className="alert-type" severity="success">
      <AlertTitle>Registration Successfull</AlertTitle>
      Signin to Donate — <strong>check it out!</strong>
    </Alert>
  );
}
function Toast() {
  return (
    <div>
      <DescriptionAlerts />
    </div>
  );
}

export default Toast;
