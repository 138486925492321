import React from "react";
import "../../styles/points.css";
const Points = () => {
  return (
    <div className="points">
      <a href="/">
        <div>
          <img
            alt="points img"
            src="https://bold-app-prod.s3.us-east-2.amazonaws.com/cashback.svg"
          />
          <div>
            <h3 className="post__heading" style={{ marginBottom: ".9rem" }}>
              Take the edunomics.in Cash Back Hacks Quiz
            </h3>
            <h4 className="un-h4">
              Unlock little-known tactics for savings and cash back with
              personalized recommendations from this 1-minute quiz.
            </h4>
          </div>
        </div>
        <div>
          <div className="points__count">
            <span>100 </span>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.857 2.85764V0.000488281H7.14269V2.85764C7.14269 4.43603 8.42249 5.7148 9.99985 5.7148C11.5782 5.7148 12.857 4.43603 12.857 2.85764Z"
                fill="#5D57FB"
              ></path>
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14.2855 0.000488281V2.85764C14.2855 4.435 15.5643 5.7148 17.1427 5.7148H19.9998V0.000488281H14.2855Z"
                fill="#5D57FB"
              ></path>
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.71431 2.85716V0H0V5.71431H2.85716C4.43554 5.71431 5.71431 4.43554 5.71431 2.85716Z"
                fill="#5D57FB"
              ></path>
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.71431 10.0007C5.71431 8.42233 4.43554 7.14355 2.85716 7.14355H0V12.8579H2.85716C4.43554 12.8579 5.71431 11.5791 5.71431 10.0007Z"
                fill="#5D57FB"
              ></path>
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M19.9996 14.2861H17.1424C15.5651 14.2861 14.2853 15.5649 14.2853 17.1433V20.0004H19.9996V14.2861Z"
                fill="#5D57FB"
              ></path>
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.857 10.0007C12.857 8.42233 11.5782 7.14355 9.99985 7.14355C8.42249 7.14355 7.14269 8.42233 7.14269 10.0007C7.14269 11.5791 8.42249 12.8579 9.99985 12.8579C11.5782 12.8579 12.857 11.5791 12.857 10.0007Z"
                fill="#5D57FB"
              ></path>
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.99998 14.2861C8.4216 14.2861 7.14283 15.5649 7.14283 17.1433V20.0004H12.8571V17.1433C12.8571 15.5649 11.5784 14.2861 9.99998 14.2861Z"
                fill="#5D57FB"
              ></path>
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2.85729 14.2861H0.000133514V20.0004H5.71445V17.1433C5.71445 15.5649 4.43568 14.2861 2.85729 14.2861Z"
                fill="#5D57FB"
              ></path>
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M17.1425 7.14355C15.5642 7.14355 14.2854 8.42233 14.2854 10.0007C14.2854 11.5781 15.5642 12.8579 17.1425 12.8579H19.9997V7.14355H17.1425Z"
                fill="#5D57FB"
              ></path>
            </svg>
          </div>
        </div>
      </a>
    </div>
  );
};

export default Points;
