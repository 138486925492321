


// const API_URL='http://localhost:4000/api/'
const API_URL='https://edufunding.api.appsdeployer.com/api/'


// import axios from "axios";
// const axiosInstance = axios.create({
//     baseURL: API_URL, // Replace with your base URL
//     // You can add other Axios configurations here
//   });
  
export default API_URL;


