import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import "../../styles/privacy.css";

function Privacy() {
  return (
    <div>
      <Header />
      <div className="pri-main">
        <h1>Edunomics Privacy Policy</h1>
        <p>
          Your privacy is important to you, so it’s important to us. While we
          understand why privacy policies are complicated and include our
          lawyer’s full version below, we’ve also put together the highlights to
          make it easy for you to see how we maintain your privacy.
        </p>
      </div>
      <div className="pri-main2">
        <h1>Edunomics Privacy Policy</h1>
        <p>
          Your privacy is important to you, so it’s important to us. While we
          understand why privacy policies are complicated and include our
          lawyer’s full version below, we’ve also put together the highlights to
          make it easy for you to see how we maintain your privacy.
        </p>
        <h2>Your privacy matters to you and it matters to us.</h2>
        <p>
          At Edunomics, your privacy is a top priority. The information provided
          below is not just so we can make our lawyers happy, but to show you
          that we can be trusted with your information. If you are a California
          resident, please see our
        </p>
        <h2>Your information that we collect</h2>
        <p>
          When you visit the Site, we automatically collect certain information
          about your device, including information about your web browser, IP
          address, time zone, and some of the cookies that are installed on your
          device. Additionally, as you browse the Site, we collect information
          about the individual web pages or products that you view, what
          websites or search terms referred you to the Site, and information
          about how you interact with the Site. This information is collected
          using the following technologies:
        </p>
        <li>
          “Cookies” are data files that are placed on your device or computer
          and often include an anonymous unique identifier. For more information
          about cookies, and how to disable cookies.
        </li>
        <li>
          “Log files” track actions occurring on the Site, and collect data
          including your IP address, browser type, Internet service provider,
          referring/exit pages, and date/time stamps.
        </li>
        <li>
          “Web beacons,” “tags,” and “pixels” are electronic files used to
          record information about how you browse the Site.
        </li>
        <p>
          We may also collect personal information from you that includes your
          name and email address, and such other information as we may require
          to provide our services. Also, if you contact us, we collect
          additional information such as your location, and any personal
          information that you provide in your contact request. In addition, we
          may receive information about you from other online or offline
          sources, including third parties from whom we validate consumer
          self-reported information, and verify information we already have
          about you. This helps us to update, expand and analyze our records and
          provide products and services that may be of interest to you.
        </p>
        <h2>How we use your information</h2>
        <p>
          We use your information to provide our services. Of course, you can
          opt-out at any time by providing us notice that you want us to delete
          and no longer use your information.
          <br />
          <br /> We also use information that we collect to help us screen for
          potential risk and fraud (in particular, your IP address), and more
          generally to improve and optimize our Site (for example, by generating
          analytics about how our customers browse and interact with the Site.
          <br />
          <br />
          Additionally, Edunomics participates in affiliate advertising programs
          designed to provide a means for edunomics to generate revenue by
          advertising and linking to those affiliates’ products or services.
          When such affiliate relationships exist and there is an opportunity
          for edunomics to be compensated for referring you to the affiliate,
          edunomics will clearly and conspicuously disclose that to you.
        </p>
        <h2>How we share your information</h2>
        <p>
          We will share your information with scholarship providers, applicable
          academic institutions, and as otherwise necessary to provide the
          service to you. By using the edunomics service, you acknowledge,
          confirm, and consent to: (i) edunomics generation of revenue from its
          partnerships with scholarship providers, applicable academic
          institutions, and other third parties who are necessary for edunomics
          to provide its services to you, and (ii) edunomics use of your
          information and data on edunomics platform in order to do so. This
          includes third party processors (“Sub-processors”) which generally
          refers to technology vendors that need access to and use of your
          personal information to give us additional personal data about you,
          and to perform analytics and other work that we may need to outsource.
          These Sub-processors are bound by law and/or contract to protect the
          confidentiality and security of your personal information. They only
          process your personal information to provide requested services for
          us, and only act on our documented instructions. <br />
          <br /> For example, we use Google Analytics to help us understand how
          our customers use the Site. You can read more about how Google uses
          your information here. You can also opt-out of Google Analytics here.{" "}
          <br />
          <br />
          In addition to Google Analytics, we may share your information with
          other third parties in an aggregated and anonymous manner to assess
          the overall trends and information about our customers. This
          information will not contain any of your personal information and is
          only shared in an aggregated and anonymized manner. <br />
          <br /> Additionally, if you indicate you are interested in particular
          educational institutions in your profile or via other means when using
          edunomics platform, edunomics may share your data with those
          educational institutions from time to time. If you do not want
          edunomics to share your data with those educational institutions at
          all, please let us know by contacting privacy@edunomics.in.{" "}
          <br />
          <br />
          Finally, we may also share your information to comply with applicable
          laws and regulations, to respond to a subpoena, search warrant or
          other lawful request for information we receive, or to otherwise
          protect our rights.
        </p>
        <h2>Some information security basics</h2>
        <p>
          The security of your information is very important. To help protect
          your information, we maintain reasonable physical, technical and
          administrative safeguards. We update and test our security technology
          on an ongoing basis. We restrict access to your personal information
          to those employees who need to know that information in order to
          provide services to you. In addition, we train our employees about the
          importance of confidentiality and maintaining the privacy and security
          of your information.
        </p>
        <h2>For our international users</h2>
        <p>
          The Site and the Services we offer through it are U.S. based and are
          controlled, operated and administered by and its Sub-processors from
          offices and servers within the United States. The Site and this
          Privacy Policy is strictly intended for audiences located in the
          United States and are provided in accordance with and subject to
          applicable United States law. If you decide to continue to access our
          Site from your location outside the United States, you hereby agree
          that your use of the Site or any services we may offer are subject to
          this Policy and your personal information will be transferred or
          processed in the United States, and you may have fewer privacy rights
          and protections than you have in your country of origin.
        </p>
        <h2>How to contact us</h2>
        <p>
          For more information about our privacy practices, if you have
          questions, or if you would like to make a complaint, please contact us
          by e-mail at privacy@edunomics.in or by mail using the details
          provided below: <br />
          <br />
          Effective Date: January 18, 2021.
          <br />
          <br /> edunomics.in, Inc., 133 Kearny #400, San Francisco CA
          94108 United States
        </p>
      </div>

      <Footer />
    </div>
  );
}

export default Privacy;
