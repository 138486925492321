import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import "../../styles/scholar.css";

function ScholarRules() {
  return (
    <div>
      <Header />
      <div className="sch-top">
        <h1>Official Scholarship Rules</h1>
        <p style={{ fontWeight: "bold" }}>
          By applying for a scholarship, you agree to be bound by these Official
          Scholarship Rules, and you understand that any violation of these
          Official Rules shall result in your disqualification from the
          scholarship. If you do not agree to these Official Scholarship Rules,
          you may not apply for, nor accept, a scholarship
        </p>
        <p>
          The edunomics.in Terms of Use, Privacy Policy and any additional
          terms and conditions listed as applicable to a particular scholarship
          shall be deemed an integral part of these Official Scholarship Rules.
          edunomics.in reserves the right to amend or discontinue
          scholarships and these Official Scholarship Rules at any time in its
          sole discretion. Please note, if you are under 18, your parent or
          guardian must read and consent tobr these Official Scholarship Rules
          on your behalf.
        </p>
        <h2>1. GENERAL CONDITIONS</h2>
        <p style={{ marginTop: "1rem" }}>
          <bold style={{ fontWeight: "bold" }}>Eligibility.</bold> You may apply
          for a chance to win a scholarship only if you meet the eligibility
          requirements set forth on the webpage for the particular scholarship.
          Additionally, scholarships are non-transferable and must be accepted
          as awarded and will not be paid to academic institutions outside of
          the United States. Without limiting the foregoing, you may not be an
          officer, director, member or employee of edunomics.in or any
          other party associated with the development or administration of the
          scholarship, or an immediate family member (i.e., parents, children,
          siblings or spouse) of any of the foregoing, or a person living in the
          household of any of these individuals, whether or not related.
          Further, edunomics.in reserves the right to disqualify any
          applicant from the scholarship in its sole discretion at any time.
        </p>
        <p>
          <bold style={{ fontWeight: "bold" }}>Application.</bold> No purchase
          or payment is necessary to apply for scholarships. Applicants must
          meet the scholarship’s eligibility requirements, submit a complete
          application through the edunomics.in platform by the application
          deadline, and agree to be bound by these Official Scholarship Rules.
          edunomics.in expressly reserves the right to disqualify any
          applicant and/or applications that are inaccurate, incomplete, or that
          edunomics.in believes in good faith to be generated by an
          automated means or scripts or are the result of plagiarism.
        </p>
        <p>
          <bold style={{ fontWeight: "bold" }}>Selection.</bold> Accepted
          scholarship applications will be reviewed by representatives of
          edunomics.in or the scholarship provider to determine the winner
          of each scholarship. All decisions regarding the scholarship,
          including without limitation the selection of the winner, shall be
          final and binding in all respects. edunomics.in reserves the
          right not to award a scholarship if, in its sole discretion, it does
          not receive any qualified applications.
        </p>
        <p>
          <bold style={{ fontWeight: "bold" }}>Notification.</bold> Scholarship
          recipients will be notified via the contact information provided to
          edunomics.in during the application process. Scholarship
          recipients may be subject to verification of eligibility and must be,
          at all times, in compliance with these Official Scholarship Rules.
          Although information that you provide may be verified,
          edunomics.in is not under any obligation to verify any of the
          information that you provide, and you hereby warrant that all
          information that you provide in your application and otherwise in
          connection with the scholarship is complete and accurate. If an award
          notification is returned as undeliverable or a potential winner does
          not supply the required verification within the time specified, the
          award will be forfeited and may be awarded to an alternate recipient
          at edunomics.in’s sole discretion.
        </p>
        <p>
          <bold style={{ fontWeight: "bold" }}> Awarding.</bold>
          After successful notification of a scholarship award and verification,
          edunomics.in will provide payment of the award to the
          scholarship winner’s accredited academic institution to be applied
          towards tuition after receiving proof of enrollment. Unless otherwise
          stated, no scholarship monies will be paid directly to the winner.
        </p>
        <p style={{ marginBottom: "6rem" }}>
          <bold style={{ fontWeight: "bold" }}> General Terms.</bold>
          Scholarship recipients, by acceptance of their award, agree to release
          edunomics.in, its employees, representatives, agents,
          contractors, assigns, advertisers, officers, directors, and
          shareholders from any and all liability, loss, damage, cost, claim
          arising out of their participation in the scholarship.
          edunomics.in reserves the right to modify or terminate
          scholarships at any time for any reason in its sole discretion, with
          or without notice. The scholarship winner is solely responsible for
          any state or federal tax liability in association with acceptance of a
          scholarship. If any provision of the terms and conditions of a
          scholarship or these Official Scholarship Rules shall be held void,
          voidable, or unenforceable, the remaining provisions shall remain in
          full force and effect. edunomics.in is not affiliated with,
          sponsored by, or endorsed by any academic institution.
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default ScholarRules;
