import React from "react";
import EnrollForm from "./EnrollForm";

function EnrollmentS() {
  return (
    <div>
      <EnrollForm />
    </div>
  );
}

export default EnrollmentS;
