import React from "react";
import "../../styles/notfound.css";
import not from "../../media/not.gif";

function NotFound() {
  return (
    <div class="wrapper">
      <img src={not} alt="" />
    </div>
  );
}

export default NotFound;
